import '../App.css';
import Card from 'react-bootstrap/Card'
import Carousel from 'react-bootstrap/Carousel'

function SecondSection() {
  return (
      <section id="second" className="wrapper style2 special">
          <div className="container">
              <header className="major">
                  <h2>Марки картона</h2>
                  <p>Работаем с  картоном следующих марок:</p>
              </header>

              <Card>
                  <Card.Body>
                      <div className="row">
                          <div className="col-12 col-lg-4">
                              <Carousel>
                                  <Carousel.Item>
                                      <img
                                          className="d-block w-100"
                                          src="../images/marks/mark-brown-b-1.jpg"
                                          alt="Гофрокартон марки «Т-23» гофра «В», бурый."
                                      />
                                  </Carousel.Item>

                                  <Carousel.Item>
                                      <img
                                          className="d-block w-100"
                                          src="../images/marks/mark-brown-b-2.jpg"
                                          alt="Гофрокартон марки «Т-23» гофра «В», бурый."
                                      />
                                  </Carousel.Item>

                                  <Carousel.Item>
                                      <img
                                          className="d-block w-100"
                                          src="../images/marks/mark-brown-b-3.jpg"
                                          alt="Гофрокартон марки «Т-23» гофра «В», бурый."
                                      />
                                  </Carousel.Item>
                              </Carousel>
                          </div>
                          <div className="col-12 col-lg-8">
                              <Card.Title>Гофрокартон марки «Т-23» гофра «В», бурый.</Card.Title>
                              <Card.Text>Подходит для изготовления коробок и лотков любого назначения по размерам заказчика, самосборных коробок под архивные документы. Толщина - 3 мм.</Card.Text>
                          </div>
                      </div>
                  </Card.Body>
              </Card>

              <Card>
                  <Card.Body>
                      <div className="row">
                          <div className="col-12 col-lg-4">
                              <Carousel>
                                  <Carousel.Item>
                                      <img
                                          className="d-block w-100"
                                          src="../images/marks/mark-brown-white-b-1.jpg"
                                          alt="Гофрокартон марки «Т-23» гофра «В», c беленым поверхностным слоем."
                                      />
                                  </Carousel.Item>

                                  <Carousel.Item>
                                      <img
                                          className="d-block w-100"
                                          src="../images/marks/mark-brown-white-b-2.jpg"
                                          alt="Гофрокартон марки «Т-23» гофра «В», c беленым поверхностным слоем."
                                      />
                                  </Carousel.Item>

                                  <Carousel.Item>
                                      <img
                                          className="d-block w-100"
                                          src="../images/marks/mark-brown-white-b-3.jpg"
                                          alt="Гофрокартон марки «Т-23» гофра «В», c беленым поверхностным слоем."
                                      />
                                  </Carousel.Item>
                              </Carousel>
                          </div>
                          <div className="col-12 col-lg-8">
                              <Card.Title>Гофрокартон марки «Т-23» гофра «В», c беленым поверхностным слоем.</Card.Title>
                              <Card.Text>Используется для изготовления четырехклапанных коробок под мелкую бытовую технику и кондитерские изделия, тортовых коробок по размерам заказчика и также самосборных коробок для упаковки пиццы, пирогов и других кондитерских изделий. Толщина - 3 мм.</Card.Text>
                          </div>
                      </div>
                  </Card.Body>
              </Card>

              <Card>
                  <Card.Body>
                      <div className="row">
                          <div className="col-12 col-lg-4">
                              <Carousel>
                                  <Carousel.Item>
                                      <img
                                          className="d-block w-100"
                                          src="../images/marks/mark-brown-white-e-1.jpg"
                                          alt="Гофрокартон марки «Т-23» гофра «Е» (микрогофрокартон), с беленым поверхностным слоем."
                                      />
                                  </Carousel.Item>

                                  <Carousel.Item>
                                      <img
                                          className="d-block w-100"
                                          src="../images/marks/mark-brown-white-e-2.jpg"
                                          alt="Гофрокартон марки «Т-23» гофра «Е» (микрогофрокартон), с беленым поверхностным слоем."
                                      />
                                  </Carousel.Item>

                                  <Carousel.Item>
                                      <img
                                          className="d-block w-100"
                                          src="../images/marks/mark-brown-white-e-3.jpg"
                                          alt="Гофрокартон марки «Т-23» гофра «Е» (микрогофрокартон), с беленым поверхностным слоем."
                                      />
                                  </Carousel.Item>
                              </Carousel>
                          </div>
                          <div className="col-12 col-lg-8">
                              <Card.Title>Гофрокартон марки «Т-23» гофра «Е» (микрогофрокартон), с беленым поверхностным слоем.</Card.Title>
                              <Card.Text>Используется для изготовления четырехклапанных коробок под мелкую бытовую технику и кондитерские изделия, тортовых коробок по размерам заказчика, а также для самосборных коробок для упаковки пиццы, пирогов и других кондитерских изделий. Толщина - 1.8 мм.</Card.Text>
                          </div>
                      </div>
                  </Card.Body>
              </Card>

              <Card>
                  <Card.Body>
                      <div className="row">
                          <div className="col-12 col-lg-4">
                              <Carousel>
                                  <Carousel.Item>
                                      <img
                                          className="d-block w-100"
                                          src="../images/marks/mark-brown-1.jpg"
                                          alt="Картон коробочный, бурый"
                                      />
                                  </Carousel.Item>

                                  <Carousel.Item>
                                      <img
                                          className="d-block w-100"
                                          src="../images/marks/mark-brown-2.jpg"
                                          alt="Картон коробочный, бурый"
                                      />
                                  </Carousel.Item>

                                  <Carousel.Item>
                                      <img
                                          className="d-block w-100"
                                          src="../images/marks/mark-brown-3.jpg"
                                          alt="Картон коробочный, бурый"
                                      />
                                  </Carousel.Item>
                              </Carousel>
                          </div>
                          <div className="col-12 col-lg-8">
                              <Card.Title>Картон коробочный, бурый</Card.Title>
                              <Card.Text>Используется для изготовления лотков, поддонов и коробок с крышками под пироги, торты и другие кондитерские изделия. Толщина - 0,8 мм.</Card.Text>
                          </div>
                      </div>
                  </Card.Body>
              </Card>

          </div>
      </section>
  );
}

export default SecondSection;
