import '../App.css';
import {
    Link
} from "react-scroll";
import Dropdown from 'react-bootstrap/Dropdown'

function Header() {
  return (
    <div id="header">
      <header className="App-header">
          <div className="container">
              <nav id="menu" className="d-none d-md-block">
                  <ul>
                      <Link
                          activeClass="active"
                          className="test1"
                          to="banner"
                          spy={true}
                          smooth={true}
                          duration={300}
                          offset={0}
                      >
                          Главная
                      </Link>

                      <Link
                          activeClass="active"
                          to="one"
                          spy={true}
                          smooth={true}
                          duration={300}
                          offset={-75}
                      >
                          Продукция
                      </Link>

                      <Link
                          activeClass="active"
                          to="second"
                          spy={true}
                          smooth={true}
                          duration={300}
                          offset={-75}
                      >
                          Марки картона
                      </Link>

                      <Link
                          activeClass="active"
                          to="partners"
                          spy={true}
                          smooth={true}
                          duration={300}
                          offset={-75}
                      >
                          Партнёры
                      </Link>

                      <Link
                          activeClass="active"
                          to="contacts"
                          spy={true}
                          smooth={true}
                          duration={300}
                          offset={-75}
                      >
                          Контакты
                      </Link>
                  </ul>
              </nav>

              <nav id="menu" className="d-md-none">

                  <div className="row">
                      <div className="col-7">
                          <h2>«Прогресс»</h2>
                      </div>
                      <div className="col-5">
                          <Dropdown>
                              <Dropdown.Toggle className="mobile-menu-burger" id="dropdown-basic">
                                  <div className="line"></div>
                                  <div className="line"></div>
                                  <div className="line"></div>
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                  <Link
                                      activeClass="active"
                                      className="test1"
                                      to="banner"
                                      spy={true}
                                      smooth={true}
                                      duration={300}
                                      offset={0}
                                  >
                                      Главная
                                  </Link>

                                  <Link
                                      activeClass="active"
                                      to="one"
                                      spy={true}
                                      smooth={true}
                                      duration={300}
                                      offset={-75}
                                  >
                                      Продукция
                                  </Link>

                                  <Link
                                      activeClass="active"
                                      to="second"
                                      spy={true}
                                      smooth={true}
                                      duration={300}
                                      offset={-75}
                                  >
                                      Марки картона
                                  </Link>

                                  <Link
                                      activeClass="active"
                                      to="partners"
                                      spy={true}
                                      smooth={true}
                                      duration={300}
                                      offset={-75}
                                  >
                                      Партнёры
                                  </Link>

                                  <Link
                                      activeClass="active"
                                      to="contacts"
                                      spy={true}
                                      smooth={true}
                                      duration={300}
                                      offset={-75}
                                  >
                                      Контакты
                                  </Link>
                              </Dropdown.Menu>
                          </Dropdown>
                      </div>
                  </div>


              </nav>
          </div>
      </header>
    </div>
  );
}

export default Header;
