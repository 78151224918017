import '../App.css';
import Carousel from 'react-bootstrap/Carousel'
import Image from 'react-bootstrap/Image'

function FirstSection() {
  return (
      <section id="one" className="wrapper style1 special">
          <div className="container">
              <div className="row">
                  <div className="col">
                      <header className="major mb-3">
                          <h2>Продукция</h2>
                          <p>Мы работаем с высококачественным картоном для изготовления коробок и лотков по размерам заказчика. Весь картон сертифицирован, хорошего качества, предназначен для упаковки, в том числе и пищевых продуктов. Ниже представлен перечень продукции:</p>
                      </header>
                  </div>
              </div>
              <div className="row">
                  <div className="col-lg-6">
                      <div className="products-list">
                          <ul>
                              <li>Коробки самосборные под пиццу, размеры от 320*320 мм. до 450*450 мм.</li>
                              <li>Коробки самосборные под пироги, любых размеров.</li>
                              <li>Лотки самосборные под кондитерские изделия любых размеров и форм.</li>
                              <li>Коробки тортовые максимальный размер раскроя 1000*1000 мм.</li>
                          </ul>
                      </div>
                  </div>
                  <div className="col-lg-6">
                      <div className="products-list">
                          <ul>
                              <li>Короба самосборные под архив под формат А-4 и А-3.</li>
                              <li>Короба не самосборные по размерам заказчика.</li>
                              <li>Коробки четырехклапанные любого назначения по размерам заказчика.</li>
                              <li>Прокладки, обрешетки  и обечайки любого размера.</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <div className="row">
                  <div className="col-lg-12">
                      <Carousel>
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src="../images/products/pizza.jpg"
                                  alt="First slide"
                              />
                              <Carousel.Caption>
                                  <p>Упаковка для пиццы</p>
                              </Carousel.Caption>
                          </Carousel.Item>
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src="../images/products/cookie-box.jpg"
                                  alt="Third slide"
                              />

                              <Carousel.Caption>
                                  <p>Упаковка для печенья</p>
                              </Carousel.Caption>
                          </Carousel.Item>
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src="../images/products/print-pattern.jpg"
                                  alt="Third slide"
                              />

                              <Carousel.Caption>
                                  <p>Пример шелко-трафаретной печати</p>
                              </Carousel.Caption>
                          </Carousel.Item>
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src="../images/products/solt-box.jpg"
                                  alt="Third slide"
                              />

                              <Carousel.Caption>
                                  <p>Упаковка для соли</p>
                              </Carousel.Caption>
                          </Carousel.Item>
                          <Carousel.Item>
                              <img
                                  className="d-block w-100"
                                  src="../images/products/print-pattern-second.jpg"
                                  alt="Third slide"
                              />

                              <Carousel.Caption>
                                  <p>Пример шелко-трафаретной печати</p>
                              </Carousel.Caption>
                          </Carousel.Item>
                      </Carousel>
                  </div>
              </div>

              <div className="block-blind">
                  <div className="col-first">
                      <a href="https://www.vos.org.ru/" target="_blank">
                        <Image src="images/blind-unite-logo-black.png" alt=""/>
                      </a>
                  </div>
                  <div className="col-second">
                      <h3>Социальный вклад</h3>
                      <p>Наше предприятие является единственным в Оренбургской области, где используется труд инвалидов по зрению. Выбирая нас, Вы вносите свою лепту в поддержку и обеспечение равных возможностей незрячих в жизни общества. </p>
                  </div>
              </div>
          </div>
      </section>
  );
}

export default FirstSection;
