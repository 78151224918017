import '../App.css';
import YMapsContainer from '../components/yandex-map'

function ContactsSection() {
  return (
      <section id="contacts" className="wrapper">
          <div className="container">
              <div className="row">
                  <div className="col">
                      <header className="major">
                          <h2>Контакты</h2>
                      </header>
                  </div>
              </div>
              <div className="row">
                  <div className="col-sm-12 col-lg-5 col-xl-6">
                      <p>Наименование: <br className="d-none d-lg-block"/>ООО «Оренбургское предприятие «Прогресс»</p>
                      <p>Юридический адрес: <br className="d-none d-lg-block"/>460036, г. Оренбург, ул. Луговая, 89, литер В, офис 24</p>
                      <p>Почтовый адрес: <br className="d-none d-lg-block"/>460036, г. Оренбург, ул. Луговая, 89, литер В</p>
                      <p>Электронный адрес: <br className="d-none d-lg-block"/><a href="mailto:56progress@mail.ru" target="_blank">56progress@mail.ru</a></p>
                      <p>Телефон/факс: <br className="d-none d-lg-block"/><a href="tel:+73532335208">+7(3532)33-52-08</a></p>
                      <p>Телефон коммерческого отдела: <br className="d-none d-lg-block"/><a href="tel:+73532335213">+7(3532)33-52-13</a></p>
                  </div>

                  <div className="col-sm-12 col-lg-5 col-xl-6">
                      <div className="google-map">
                          <YMapsContainer />
                      </div>
                  </div>
              </div>

          </div>


      </section>
  );
}

export default ContactsSection;
