import '../App.css';
import Image from 'react-bootstrap/Image'

function PartnersSection() {
  return (
      <section id="partners" className="wrapper">
          <div className="container">
              <div className="row">
                  <div className="col">
                      <header className="major">
                          <h2>Партнёры</h2>
                          <p>С данными компаниями мы ведем долгосрочное, плодотворное сотрудничество.</p>
                      </header>
                  </div>
              </div>
              <div className="row">
                  <div className="col-xs-6 col-md-4 col-xl-4">
                      <a href="https://stclim.ru" target="_blank">
                          <Image src="images/partners/partner-aventa.png" alt="Студия климата"></Image>
                      </a>
                  </div>

                  <div className="col-xs-6 col-md-4 col-xl-4">
                      <a href="https://camoncafe.ru" target="_blank">
                        <Image src="images/partners/partner-kamon.png" alt="Кафе Камон"></Image>
                      </a>
                  </div>

                  <div className="col-xs-6 col-md-4 col-xl-4">
                      <a href="http://www.velichestvopizza.ru" target="_blank">
                        <Image className="smaller" src="images/partners/partner-pizza.png" alt=""></Image>
                      </a>
                  </div>

                  <div className="col-xs-6 col-md-4 col-xl-4">
                      <a href="https://rasstegai56.ru" target="_blank">
                          <Image src="images/partners/partner-rastegai.png" alt="Студия климата"></Image>
                      </a>
                  </div>

                  <div className="col-xs-6 col-md-4 col-xl-4">
                      <a href="https://salam56.ru" target="_blank">
                          <Image src="images/partners/partner-salyam.svg" alt="Кафе Камон"></Image>
                      </a>
                  </div>

                  <div className="col-xs-6 col-md-4 col-xl-4">
                      <a href="http://yamakasi56.ru" target="_blank">
                          <Image src="images/partners/partner-yamakasi.png" alt=""></Image>
                      </a>
                  </div>
              </div>

          </div>


      </section>
  );
}

export default PartnersSection;
