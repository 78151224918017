import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from "./components/header";
import Banner from "./components/banner";
import FirstSection from "./components/first-section";
import SecondSection from "./components/second-section";
import PartnersSection from "./components/partners-section";
import ContactsSection from "./components/contacts-section";
import Footer from "./components/footer";

function App() {
  return (
    <div className="App">
        <Header />
        <Banner />
        <FirstSection />
        <SecondSection />
        <PartnersSection />
        <ContactsSection />
        <Footer />
    </div>
  );
}

export default App;
