import '../App.css';
import Image from 'react-bootstrap/Image'
import {
    Link
} from "react-scroll";
import Container from 'react-bootstrap/Container'

function Banner() {
  return (
      <section id="banner">
          <Container>
          <div className="banner-inner">
              <Image src="logo-white.png" />
              <h2>Прогресс</h2>
              <p>Весь спектр картонных изделий</p>
              <p className="small-tags">
                  (Картонная упаковка, картонный короб, упаковка под пиццу, коробка для архива, коробка для хранения, упаковка для кондитерских изделий, упаковка для подарков, коробка для торта и т.д.)
              </p>
              <ul className="actions special">
                  <li><a href="tel:+73532335208" className="button primary">Связаться</a></li>
              </ul>

              <Link
                  activeClass="active"
                  className="more scrolly"
                  to="one"
                  spy={true}
                  smooth={true}
                  duration={300}
                  offset={-75}
              >
                  Узнать подробности
              </Link>
          </div>

          </Container>
      </section>
  );
}

export default Banner;
