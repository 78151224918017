import '../App.css';

function Footer() {
  return (
    <div id="footer">
      <footer className="App-header">
          <div className="container">
              <div className="row">
                  <div className="col">
                      <p className="copyright">ООО «Оренбургское предприятие «Прогресс» - Все права защищены - 2021 - 2024</p>
                  </div>
              </div>
          </div>
      </footer>
    </div>
  );
}

export default Footer;
