import React from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';

const YMapsContainer = () => (
    <YMaps>
        <div>
            <Map className="yandex-map" defaultState={{ center: [51.788985165370086, 55.15789055819695], zoom: 16 }}>
                <Placemark geometry={[51.788985165370086, 55.15789055819695]} />
            </Map>
        </div>
    </YMaps>
);
export default YMapsContainer;